// modern-normalize -  https://github.com/sindresorhus/modern-normalize
import 'modern-normalize/modern-normalize.css';

// Animate on scroll library (AOS) - https://github.com/michalsnik/aos
import 'aos/dist/aos.css';
import AOS from 'aos';
AOS.init({
	duration: 1200,
})

// BeerSlider - https://github.com/pehaa/beerslider
import 'beerslider/dist/BeerSlider.css';
import BeerSlider from 'beerslider/dist/BeerSlider.js';
var slider = new BeerSlider(document.getElementById("beer-slider"));

// Vanilla Lazyload - https://www.andreaverlicchi.eu/vanilla-lazyload/
import LazyLoad from "vanilla-lazyload";
var lazyLoadInstance = new LazyLoad({
  // Your custom settings go here
});

import './inc/css/skin.css';
